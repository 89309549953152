const checkIfWalletSupported = (wallet, supportedWalletsDict) => Boolean(supportedWalletsDict[wallet.type]);
const mapSupportedWalletsDict = (supportedWallets = []) => {
    const walletsDict = {};
    supportedWallets.forEach((wallet) => {
        walletsDict[wallet] = true;
    });
    return { data: walletsDict };
};
const getWalletsFromExchanges = (exchanges) => {
    const wallets = [];
    exchanges.forEach((exchange) => {
        if (exchange.wallets.length) {
            exchange.wallets.forEach((wallet) => {
                wallets.push(Object.assign(Object.assign({}, wallet), { exchange }));
            });
        }
    });
    return wallets;
};
const mapWalletForOverview = (wallet, portfolios) => {
    const connectedPortfolio = portfolios.find(p => p.walletId === wallet.id);
    return (Object.assign(Object.assign({}, wallet), { balance_diff_in_currency: 0, balance_diff_in_percentage: 0, wallet_type: connectedPortfolio ? 'portfolio' : 'wallet' }));
};
export { checkIfWalletSupported, mapSupportedWalletsDict, getWalletsFromExchanges, mapWalletForOverview, };
