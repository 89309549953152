import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTheme } from '@mui/material';
const AssetBarItem = ({ color, width, borderRadiusPosition, }) => {
    const theme = useTheme();
    const borderRadius = useMemo(() => {
        if (borderRadiusPosition === 'left') {
            return `${theme.spacing_sizes.s}px 0 0 ${theme.spacing_sizes.s}px`;
        }
        if (borderRadiusPosition === 'right') {
            return `0 ${theme.spacing_sizes.s}px ${theme.spacing_sizes.s}px 0`;
        }
        if (borderRadiusPosition === 'left-and-right') {
            return theme.spacing_sizes.s;
        }
        return 'unset';
    }, [
        borderRadiusPosition,
        theme,
    ]);
    return (_jsx("div", { style: {
            height: 8,
            width: `${width}%`,
            backgroundColor: color,
            borderRadius,
        } }));
};
export default AssetBarItem;
