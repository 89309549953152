const AREAS_COLORS = {
    0: '#3E63DD',
    1: '#2EBDE5',
    2: '#FFA01C',
    3: '#94BA2C',
    4: '#D6409F',
    5: '#0091FF',
    6: '#EBBC00',
    7: '#3DB9CF',
    8: '#6E56CF',
    9: '#E93D82',
};
export default AREAS_COLORS;
